<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userAvatar"
            :text="avatarText(userData.fullname)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullname }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'administrator-users-edit', params: { id: userData._id } }"
                variant="outline-primary"
              >
                {{ $t("Editar") }}
              </b-button>
              <!-- <b-button
                :variant="
                  userData.status == 'active'
                    ? 'outline-danger'
                    : 'outline-success'
                "
                class="ml-1"
                v-b-modal.modal-disable-user
              >
                {{ userData.status == 'active' ? $t("Desativar") : $t("Ativar") }}
              </b-button> -->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">R$ 0,00</h5>
              <small>{{ $t("Valor gasto este mês") }}</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">R$ 0,00</h5>
              <small>{{ $t("Valor gasto este ano") }}</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6" lg="6" md="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("Usuário") }}</span>
            </th>
            <td class="pb-50">
              @{{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("Status") }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("Contato") }}</span>
            </th>
            <td>
              {{ userData.mobileNumber }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("Upline") }}</span>
            </th>
            <td class="pb-50">
              {{ userData.upline.fullname }} | {{ userData.upline.email }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <b-modal
      id="modal-disable-user"
      ok-only
      :ok-variant="userData.status == 'active' ? 'danger' : 'success'"
      :ok-title="userData.status == 'active' ? 'Desativar' : 'Ativar'"
      :modal-class="
        userData.status == 'active' ? 'modal-danger' : 'modal-success'
      "
      centered
      :title="
        userData.status == 'active'
          ? this.$i18n.t('Você está prestes a desativar este usuário')
          : this.$i18n.t('Você está prestes a ativar este usuário')
      "
      @ok="handleOk"
    >
      <b-card-text>
        {{
          userData.status == "active"
            ? $t(
                `Por favor, digite o motivo pelo qual você está desativando o usuário: `
              )
            : $t(
                `Por favor, digite o motivo pelo qual você está ativando o usuário: `
              )
        }}
        <span
          :class="userData.status == 'active' ? 'text-danger' : 'text-success'"
          >{{ userData.fullname }}</span
        >
      </b-card-text>

      <div class="mt-1">
        <validation-observer ref="disableUser">
          <form ref="form" @submit.prevent="handleSubmit">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                mode="eager"
                name="Reason"
                vid="reason"
                rules="required|min:6|max:254"
              >
                <b-form-textarea
                  id="textarea-default"
                  :state="errors.length > 0 ? false : null"
                  v-model="reasonDisable"
                  placeholder="Digite o motivo..."
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </form>
        </validation-observer>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BFormTextarea,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../users-list/useUsersList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, min, max } from "@/libs/validations";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,

    localize,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userAvatar: null,
      reasonDisable: null,

      required,
      min,
      max,
    };
  },
  mounted() {
    this.userAvatar = this.userData?.avatar ? `${process.env.VUE_APP_API_URL}/avatar/${this.userData.avatar}` : null;
  },
  methods: {
    ...mapActions(["disableUserAPI"]),
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.disableUser.validate().then((success) => {
        if (success) {
          this.handleSubmit();
        }
      });
    },
    handleSubmit() {
      const data = {
        id: this.userData._id,
        status: this.userData.status == "active" ? "inactive" : "active",
        description: this.reasonDisable,
      };
      this.disableUserAPI(data)
        .then((response) => {
          this.$bvModal.hide("modal-disable-user");
          this.$emit("fetch-data-user");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: this.userData.status == "active" ? "danger" : "success",
              title:
                this.userData.status == "active"
                  ? this.$i18n.t("Usuário desativado!")
                  : this.$i18n.t("Usuário ativado!"),
            },
          });
          this.reasonDisable = null;
        })
        .catch((err) => {
          /** */
        });
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList();
    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>

<style>
</style>
