<template>
    <b-card :title="$t('Resumo financeiro')">
        <div>
            <b-row>
                <b-col><strong>{{ $t('Valor total depositado') }}</strong></b-col>
                <b-col class="text-right text-success font-weight-bolder"> {{
                    moneyFormat(returnAPI.totalAmountDeposited) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Valor depositado (licenças)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.totalAmountDepositedLicenses) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Valor depositado (aportes)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.totalAmountDepositedContributions) }}</b-col>
            </b-row>

            <br>

            <b-row>
                <b-col><strong>{{ $t('Valor total de ativações') }}</strong></b-col>
                <b-col class="text-right text-success font-weight-bolder"> {{
                    moneyFormat(returnAPI.totalAmountSponsoredPIX) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Ativação por PIX (licenças)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.valueInSponsoredLicensePIX) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Ativação por PIX (aportes)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.valueInSponsoredContributionPIX) }}</b-col>
            </b-row>

            <br>

            <b-row>
                <b-col><strong>{{ $t('Valor total patrocinado') }}</strong></b-col>
                <b-col class="text-right text-success font-weight-bolder"> {{
                    moneyFormat(returnAPI.totalAmountSponsored) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Patrocínio (licenças)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.valueInSponsoredLicense) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Patrocínio (aportes)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.valueInSponsoredContribution) }}</b-col>
            </b-row>

            <br>

            <b-row>
                <b-col><strong>{{ $t('Valor total reinvestido') }}</strong></b-col>
                <b-col class="text-right text-success font-weight-bolder"> {{
                    moneyFormat(returnAPI.totalAmountReinvestment) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Reinvestimento (licenças)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.valueInReinvestmentLicense) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Reinvestimento (aportes)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.valueInReinvestmentContribution) }}</b-col>
            </b-row>

            <br>

            <b-row>
                <b-col><strong>{{ $t('Total de saques realizados') }}</strong></b-col>
                <b-col class="text-right text-danger font-weight-bolder"> {{ moneyFormat(returnAPI.totalWithdrawalsMade)
                    }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Saques (rendimento)') }}</strong></b-col>
                <b-col class="text-right"> {{
                    moneyFormat(returnAPI.incomeWithdrawals) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Saques (bônus)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.bonusWithdrawals) }}</b-col>
            </b-row>

            <br>

            <b-row>
                <b-col><strong>{{ $t('Total disponível para saque') }}</strong></b-col>
                <b-col class="text-right text-danger font-weight-bolder"> {{
                    moneyFormat(returnAPI.totalAvailableForWithdrawal) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Disponível para saque (bônus)') }}</strong></b-col>
                <b-col class="text-right"> {{
                    moneyFormat(returnAPI.availableForBonusWithdrawal) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Disponível para saque (rendimento)') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.availableForWithdrawalIncome) }}</b-col>
            </b-row>

            <br>

            <hr>

            <br>
            
            <b-row>
                <b-col><strong>{{ $t('Total em bônus') }}</strong></b-col>
                <b-col class="text-right text-info font-weight-bolder"> {{
                    moneyFormat(returnAPI.totalBonus) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 1º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.referenceBonus) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 2º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL2) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 3º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL3) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 4º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL4) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 5º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL5) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 6º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL6) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 7º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL7) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 8º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL8) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 9º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL9) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de 10º nível') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.unilevelBonusL10) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Bônus recebidos de binário') }}</strong></b-col>
                <b-col class="text-right">{{ moneyFormat(returnAPI.binaryBonus) }}</b-col>
            </b-row>

            <br>

            <b-row>
                <b-col><strong>{{ $t('Quantidade de planos') }}</strong></b-col>
                <b-col class="text-right text-warning font-weight-bolder"> {{
                    returnAPI.amountPlans }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Valor total em licenças') }}</strong></b-col>
                <b-col class="text-right text-warning font-weight-bolder"> {{
                    moneyFormat(returnAPI.totalValueLicense) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Valor total em aportes') }}</strong></b-col>
                <b-col class="text-right text-warning font-weight-bolder"> {{ moneyFormat(returnAPI.totalValueContribution) }}</b-col>
            </b-row>
            <b-row>
                <b-col><strong>{{ $t('Recebido de rendimentos') }}</strong></b-col>
                <b-col class="text-right text-warning font-weight-bolder"> {{ moneyFormat(returnAPI.totalValueIncome) }}</b-col>
            </b-row>           
        </div>
    </b-card>
</template>

<script>
import {
    BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    directives: {
        Ripple,
    },
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BBadge,
        BButton,
    },
    data() {
        return {
            returnAPI: {}
        }
    },
    props: {
        idUser: String
    },
    mounted() {
        this.financialSummary()
    },
    methods: {
        financialSummary() {
            this.$store.dispatch("userFinancialSummary", this.idUser)
                .then((resp) => {
                    this.returnAPI = resp
                });
        }
    }
}
</script>