<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>{{ $t("Permissões") }}</b-card-title>
      <b-card-sub-title class="mb-2">
        {{ $t('De acordo com os grupos de permissões do usuário') }}
      </b-card-sub-title>
      <app-collapse accordion type="default">
        <app-collapse-item
          :title="permissions.name"
          v-for="permissions in rolesData"
          :key="permissions.id"
        >
          <b-table
            striped
            responsive
            :items="permissions.permissions"
            class="mb-0"
          >
            <template #cell(modulo)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-checkbox disabled :checked="data.value" />
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,

    AppCollapse,
    AppCollapseItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rolesData: [],
    };
  },
  mounted() {
    this.userData.roles.map((e, index) => {
      this.rolesData.push({
        id: null,
        name: e.name,
        permissions: [],
      });
      e.permissions.map((el) => {
        let module =
          (el.name.split("|")[1]
            ? el.name.split("|")[1].split("_")[0] + " > "
            : "") + el.name.split("_")[1];
        let moduleRepeat = this.rolesData[index].permissions.indexOf(
          this.rolesData[index].permissions.filter(
            (item) => item.modulo == module
          )[0]
        );
        if (moduleRepeat < 0) {
          this.rolesData[index].permissions.push({
            modulo: module,
            visualizar: el.name.split("_")[2] == "view" ? true : false,
            criar: el.name.split("_")[2] == "create" ? true : false,
            editar: el.name.split("_")[2] == "edit" ? true : false,
            remover: el.name.split("_")[2] == "remove" ? true : false,
          });
        } else {
          if (el.name.split("_")[2] == "view") {
            this.rolesData[index].permissions[moduleRepeat].visualizar = true;
          }
          if (el.name.split("_")[2] == "create") {
            this.rolesData[index].permissions[moduleRepeat].criar = true;
          }
          if (el.name.split("_")[2] == "edit") {
            this.rolesData[index].permissions[moduleRepeat].editar = true;
          }
          if (el.name.split("_")[2] == "remove") {
            this.rolesData[index].permissions[moduleRepeat].remover = true;
          }
        }
      });
    });
  },
};
</script>

<style>
</style>
