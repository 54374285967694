<template>
  <b-card :title="$t('Linha do tempo do usuário')">
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
      <b-button @click="clearFilters" variant="secondary" size="sm">
        {{ $t("Limpar Filtros") }}
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Data inicial</label>
          <flat-pickr
            v-model="startDate"
            class="form-control"
            placeholder="Data inicial"
            :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
            :disabled="loadingLogs"
          />
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Data final</label>
          <flat-pickr
            v-model="endDate"
            class="form-control"
            placeholder="Data final"
            :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
            :disabled="loadingLogs"
          />
        </b-col>
      </b-row>
      <b-row class="mt-md-1">
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Categoria</label>
          <v-select
            v-model="categoryLogSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            label="title"
            :options="categoryLogOptions"
            :reduce="option => option.value"
          />
        </b-col>
      </b-row>
    </b-card-body>
    <app-timeline>
      <hr />
      <app-timeline-item v-for="(timeline, index) in logList" :key="index">
        <div
          class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6>{{ timeline.title }}</h6>
          <small class="text-muted">
            {{ formatDate(timeline.createdAt) }}
          </small>
        </div>
      </app-timeline-item>
      <div v-if="logList.length >= params.limit" class="text-center mt-1">
        <b-button
          variant="success"
          size="sm"
          class="mr-2"
          @click="getLimitLogsList"
          :disabled="loadingLogs"
        >
          <div v-if="!loadingLogs">Buscar mais</div>
          <div v-else>
            <b-spinner small type="grow"></b-spinner>
            Carregando...
          </div>
        </b-button>
      </div>
      <div v-if="logList.length < 1 && !loadingLogs" class="text-center">
        <span class="text-white h5">Logs não encontrados!</span>
      </div>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { format } from "date-fns";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BSpinner,
    AppTimeline,
    AppTimelineItem,
    vSelect,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logList: [],
      startDate: null,
      endDate: null,
      categoryLogSelected: null,
      categoryLogOptions: [
        { title: "Todos", value: "" },
        { title: "Acesso", value: "auth" },
        { title: "Licença", value: "license" },
        { title: "Depósito", value: "deposit" },
        { title: "Saque", value: "withdrawal" },
        { title: "Bônus", value: "bonus" },
        { title: "Compras", value: "purchase" },
        { title: "Rendimento", value: "income" },
      ],
      params: {
        startDate: null,
        endDate: null,
        page: 1,
        limit: 10,
        category: null,
        sortby: "createdAt",
        sortdirection: "desc",
      },
      loadingLogs: false,
    };
  },
  watch: {
    startDate() {
      this.updateDateFilters();
    },
    endDate() {
      this.updateDateFilters();
    },
    categoryLogSelected(newVal, oldVal) {
      this.params.category = newVal;
      if (newVal !== oldVal) {
        this.renderLogsUserList();
      }
    },
  },
  mounted() {
    this.renderLogsUserList();
  },
  methods: {
    updateDateFilters() {
      this.params.startDate = this.startDate
        ? format(new Date(this.startDate), "yyyy-MM-dd")
        : null;
      this.params.endDate = this.endDate
        ? format(new Date(this.endDate), "yyyy-MM-dd")
        : null;
      this.renderLogsUserList();
    },
    renderLogsUserList() {
      this.loadingLogs = true;
      this.params.idUser = this.userData._id;
      this.$store
        .dispatch("userLogsList", this.params)
        .then((resp) => {
          this.loadingLogs = false;
          this.logList = resp?.usersLogsList || [];
        })
        .catch(() => {
          this.loadingLogs = false;
          this.logList = [];
        });
    },
    clearFilters() {
      this.startDate = null;
      this.endDate = null;
      this.categoryLogSelected = null;
      this.params.startDate = null;
      this.params.endDate = null;
      this.params.category = null;
      this.renderLogsUserList();
    },
    getLimitLogsList() {
      this.params.limit += 10;
      this.renderLogsUserList();
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy HH:mm");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
