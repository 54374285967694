<template>
  <div>
    <!-- Alert: No item found -->
    <div v-if="isLoading" class="loader-container">
      <div class="spinner-border text-primary" role="status" style=" width: 2rem; height: 2rem;">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">{{ $t("Erro ao buscar dados do usuário") }}</h4>
      <div class="alert-body">
        {{ $t("Nenhum usuário encontrado com este ID de usuário. Verificar") }}
        <b-link class="alert-link" :to="{ name: 'administrator-users-list' }">
          {{ $t("Lista de usuários") }}
        </b-link>
        {{ $t("para outros usuários.") }}
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <user-view-user-info-card :user-data="userData.user" @fetch-data-user="fetchUserData" />
        </b-col>
        <!-- <b-col cols="12" md="5" xl="3" lg="4">
            <user-view-user-plan-card :user-data="userData" />
        </b-col> -->
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
          <financial-summary :id-user="userData.user._id" />
        </b-col>
        <b-col cols="12" lg="6">
          <user-view-user-timeline-card :user-data="userData.user" />
        </b-col>
        <!-- <b-col cols="12" lg="6">
          <user-view-user-permissions-card :user-data="userData" />
        </b-col> -->
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink, BOverlay } from "bootstrap-vue";
import InvoiceList from "@/views/administrator/invoice/invoice-list/InvoiceList.vue";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";
import FinancialSummary from "./FinancialSummary.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BOverlay,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    InvoiceList,
    FinancialSummary
  },
  data() {
    return {
      userData: null,
      isLoading: true,
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      this.$store
        .dispatch("fetchUser", this.$route.params.id)
        .then((response) => {
          this.userData = response;
          this.isLoading = false;
        })
        .catch((error) => {
          this.userData = undefined;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
